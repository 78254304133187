@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

/* #root{
  background-color: black;
} */

#root.login-background {
  background-color: rgb(0, 0, 0);
}
.app {
  display: flex;
  position: relative;
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pro-sidebar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  height: 100vh; /* Full-height */
  width: 250px; /* Adjust as needed */
  background-color: #333;
  z-index: 1000; /* Ensure it sits above other content */
}

.pro-sidebar-inner {
  overflow-y: auto; /* Add scrolling if content overflows */
}

.MuiBox-root.css-1j1s727 {
position: sticky;
height: 100vh;
top: 1px;
z-index: 1000;
}
/* position: sticky;
height: 100vh;
top: 1px;
z-index: 1000; */